
<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard class="cards">
        <CCardHeader>
          <CRow>
            <CCol class="styleHeader" col="6">
              {{ $t("message.salesReport") }}
              <br />
              <small>{{
                $t("message.fromDate") +
                ": " +
                fromDate +
                " - " +
                $t("message.toDate") +
                ": " +
                toDate
              }}</small>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <div class="row">
            <div class="col-md-3">
              <CFormText>
                {{ $t("message.fromDate") }}
              </CFormText>
              <input
                id="name"
                v-model="fromDate"
                pattern="\d{4}-\d{2}-\d{2}"
                type="date"
                class="filter"
                :placeholder="$t('message.fromDate')"
              />
            </div>
            <div class="col-md-3">
              <CFormText>
                {{ $t("message.toDate") }}
              </CFormText>
              <input
                id="name"
                v-model="toDate"
                pattern="\d{4}-\d{2}-\d{2}"
                type="date"
                class="filter"
                :placeholder="$t('message.toDate')"
              />
            </div>
            <div class="col-md-2">
              <CButton
                square
                color="btn btn-primary"
                class="sub"
                v-on:click="filter"
              >
                <CIcon class="c-icon" name="cil-chevron-top" /> &emsp;
                {{ $t("message.filter") }}
              </CButton>
            </div>
          </div>
          <hr />
          <table class="table table-boarder">
            <tr>
              <th>
                {{ $t("message.date") }}
              </th>
              <th colspan="4" style="width: 90% !important">
                {{ $t("message.suppliers") }}
                <tr>
                  <th style="width: 2% !important">
                    {{ $t("message.name") }}
                  </th>
                  <th style="width: 10% !important">
                    {{ $t("message.categories") }}
                  </th>
                  <th style="width: 10% !important">
                    {{ $t("message.quantity") }}
                  </th>
                  <th style="width: 10% !important">
                    {{ $t("message.amount") }}
                  </th>
                  <th style="width: 10% !important">
                    {{ $t("message.total") }}
                  </th>
                </tr>
              </th>
            </tr>
            <tr v-for="(data, index) in salesData" :key="index">
              <td>{{ data.date }}</td>
              <td>
                <tr
                  v-for="(supplier, index2) in suppliers"
                  :key="index2"
                  style="width: 100% !important"
                >
                  <td style="width: 10% !important">
                    {{ supplier.name }}
                  </td>
                  <td style="width: 100% !important">
                    <tr
                      v-for="(supplierDate, index3) in data.sales[
                        supplier.name
                      ]"
                      :key="index3"
                      style="width: 100% !important"
                    >
                      <td style="width: 10% !important">
                        {{ supplierDate.category }}
                      </td>
                      <td style="width: 10% !important">
                        {{ supplierDate.quantity }}
                      </td>
                      <td style="width: 10% !important">
                        {{ supplierDate.amount }}
                      </td>
                    </tr>
                  </td>
                </tr>
              </td>
              <td class="daily-total">
                <div ><span>{{ $t("message.dailyTotalAmount") }} : </span>  {{ data.totalAmounts }}</div>
                <br/>
                <div><span>{{ $t("message.dailyTotalQuantities") }} : </span>{{ data.totalQuantities }}</div>
              </td>
            </tr>
            <tr>
              <th>{{ $t("message.total") }}</th>
              <td>
                <tr
                  v-for="(supplier, index1) in suppliers"
                  :key="index1"
                  style="width: 100% !important"
                >
                  <td style="width: 10% !important">
                    {{ supplier.name }}
                  </td>
                  <td style="width: 100% !important">
                    <tr
                      v-for="(category, index2) in categories"
                      :key="index2"
                      style="width: 100% !important"
                    >
                      <td style="width: 10% !important">
                        {{ category.name }}
                      </td>
                      <td style="width: 10% !important">
                        {{
                          total[supplier.name][category.name][
                            "total_quantities"
                          ]
                        }}
                      </td>
                      <td style="width: 10% !important">
                        {{
                          total[supplier.name][category.name]["total_amounts"]
                        }}
                      </td>
                    </tr>
                  </td>
                </tr>
              </td>
            </tr>
          </table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import $ from 'jquery'
const date = new Date()
const fromDate =
  date.getFullYear() +
  '-' +
  (date.getMonth() + 1).toString().padStart(2, '0') +
  '-' +
  '01'
const toDate =
  date.getFullYear() +
  '-' +
  (date.getMonth() + 1).toString().padStart(2, '0') +
  '-' +
  date.getDate().toString().padStart(2, '0')
export default {
  name: 'SalesReports',
  data () {
    return {
      fromDate: fromDate,
      toDate: toDate,
      salesData: [],
      suppliers: [],
      categories: [],
      total: []
    }
  },
  created () {
    const salesData = []
    let total = {}
    let supplier = ''
    this.$http
      .get(
        `${this.$hostUrl}report/suppliers-sales?start=${this.fromDate}&end=${this.toDate}`
      )
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          if (value.date) {
            salesData.push({
              date: value.date,
              totalAmounts: value.total_amounts,
              totalQuantities: value.total_quantities,
              sales: []
            })
            $.each(value.sales, function (key, value) {
              if (value.supplier !== supplier) {
                supplier = value.supplier
                salesData[salesData.length - 1].sales[value.supplier] = [
                  {
                    category: value.category,
                    amount: value.amounts,
                    quantity: value.quantity
                  }
                ]
              } else {
                salesData[salesData.length - 1].sales[supplier].push({
                  category: value.category,
                  amount: value.amounts,
                  quantity: value.quantity
                })
              }
            })
          } else {
            total = value
          }
        })
        this.salesData = salesData
        this.total = total
        console.log(total)
      })
    const suppliers = []
    this.$http.get(`${this.$hostUrl}suppliers`).then((response) => {
      $.each(response.data.data, function (key, value) {
        suppliers.push({ id: value.id, name: value.name })
      })
      this.suppliers = suppliers
    })
    const categories = []
    this.$http.get(`${this.$hostUrl}categories`).then((response) => {
      $.each(response.data.data, function (key, value) {
        categories.push({ id: value.id, name: value.name })
      })
      this.categories = categories
    })
  },
  methods: {
    filter () {
      const salesData = []
      let supplier = ''
      this.$http
        .get(
          `${this.$hostUrl}report/suppliers-sales?start=${this.fromDate}&end=${this.toDate}`
        )
        .then((response) => {
          $.each(response.data.data, function (key, value) {
            if (value.date) {
              salesData.push({
                date: value.date,
                totalAmounts: value.total_amounts,
                totalQuantities: value.total_quantities,
                sales: []
              })
              $.each(value.sales, function (key, value) {
                if (value.supplier !== supplier) {
                  supplier = value.supplier
                  salesData[salesData.length - 1].sales[value.supplier] = [
                    {
                      category: value.category,
                      amount: value.amounts,
                      quantity: value.quantity
                    }
                  ]
                } else {
                  salesData[salesData.length - 1].sales[supplier].push({
                    category: value.category,
                    amount: value.amounts,
                    quantity: value.quantity
                  })
                }
              })
            }
          })
          this.salesData = salesData
        })
    }
  }
}
</script>
<style scoped>

</style>
